@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/holly-2');

body {
  margin: 0;
  clear: both;
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.font-cursive {
  font-family: 'Holly', sans-serif;
}
.font-serif {
  font-family: 'Noto Serif', serif;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

.text-invert {
  color: white !important;
}
.text-center {
  text-align: center;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.video-responsive {
  overflow: hidden;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-responsive iframe {
  left: 0;
  height: 80%;
  width: 100%;
  position: absolute;
}